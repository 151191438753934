<template>
  <div id="contact_welcome" class="container-content-create-account">
    <div class="">
      <!-- video -->
      <div class="has-text-left container-welcome">
        <!-- greet user -->
        <div class="title-header-section-create-account">
          <p
            class="
              title-section
              text-basier
              has-text-left
              title-regular
              dark-blue-emitus
            "
          >
            Hola {{user.firstname}}! 🎉
          </p>
        </div>
        <p class="description-nunito dark-gray-emitus">Comencemos</p>
        <form>
          <!-- email -->
          <b-field
            class="mt-4 pt-5"
            label="Email"
            :message="mensajeEmail"
            :type="messageTypeEmail"
            >
            <b-input
              v-model="email"
              :disabled="!showEmail"
              placeholder="tuemail@salgo.com"
              id="email"
              v-on:focusin.native="clearValidityEmail"
              autocomplete="off"
              >
              </b-input>
          </b-field>

          <!-- lenguage -->
          <!-- <b-field
            class="mt-5"
            label="Lenguage de soporte"
            :message="mensajeLang"
            :type="messageTypeLang"
          >
            <b-select
              v-model="supportlang"
              placeholder="Choose"
              expanded
              v-on:focusin.native="clearValidityLang"
              autocomplete="off"
            >
              <option value="English">Inglés</option>
              <option value="Spanish">Español</option>
            </b-select>
          </b-field> -->
          <!--
          <p id="supportlang_contact" class="text-nunito-light is-size-7 " style="color: red; display: none;">Campo requerido</p> -->
          <!-- phone -->
          <b-field
            class="mt-5"
            label="Teléfono móvil"
            :message="mensajePhone"
            :type="messageTypePhone"
            v-on:focusin.native="clearValidityPhone"
            autocomplete="off"
            >
            <vue-tel-input
              v-model="phone"
              v-bind="bindProps"
              id="phone"
              autocomplete="off"
              >
              </vue-tel-input>
          </b-field>
          <!-- <p id="phone_contact" class="text-nunito-light is-size-7 " style="color: red; display: none;">Campo requerido</p> -->
          <!-- update user -->
          <button class="button-primary-mini mt-6" @click.prevent="updateUser">Ok</button>
          <!--<div v-if="loader" class="lds-ring">
            <div></div>
            <span class="text-basier purple-dark-emitus loader-text">Loading...</span>
          </div>-->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { VueTelInput } from 'vue-tel-input'

export default {
  name: 'Contact',
  props: {
    user: {
      type: Object,
      required: true
    },
    name: String,
    showEmail: Boolean
  },
  data () {
    return {
      myname: '',
      email: '',
      phone: '',
      mensajeEmail: '',
      mensajePhone: '',
      mensajeLang: '',
      messageTypeEmail: '',
      messageTypePhone: '',
      messageTypeLang: '',
      valid: true,
      supportlang: 'Español',
      bindProps: {
        mode: 'international',
        preferredCountries: ['US', 'ES', 'DE', 'GB'],
        onlyCountries: [],
        ignoredCountries: []
      },
      loader: false
    }
  },
  watch: {
    user: function () {
      this.email = this.user.email
      // this.supportlang = this.user.supportlang
      this.phone = this.user.phone
    },
    name: function () {
      if (this.name !== 'undefined undefined') {
        this.myname = this.name
      }
    }
  },
  mounted () {
    // este length esta dando error por que el this.name es una cadena de texto tipo string solamente
    // if (this.name.length > 0) { this.myname = this.name }
    // this.myname = this.name
  },
  methods: {
    nextstep () {
      this.$emit('nextstep')
    },
    updateUser () {
      this.ValidityEmail()
      this.validPhone()
      this.validLang()
      // console.log(this.valid)
      if (this.email && this.phone && this.supportlang) {
        this.valid = true
      }
      // console.log(this.valid)
      if (this.valid) {
        this.loader = true
        this.$emit('updateUserContact', {
          email: this.email,
          supportlang: this.supportlang,
          phone: this.phone
        })
        // this.loader = false
      }
    },
    ValidityEmail () {
      if (!this.email) {
        this.mensajeEmail = 'We\'ll send booking notifications to this email.'
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      } else if (!this.validEmail(this.email)) {
        this.mensajeEmail = 'Algo está mal. Revisa to correo electrónico.'
        this.messageTypeEmail = 'is-primary'
        this.valid = false
      } else {
        this.messageTypeEmail = ''
        this.mensajeEmail = ''
      }
    },
    validEmail: function (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validPhone () {
      if (!this.phone) {
        this.mensajePhone = 'We\'ll send booking notifications to this phone number.'
        this.messageTypePhone = 'is-primary'
        document.getElementById('phone').classList.add('invalid-input')
        this.valid = false
      } else {
        this.mensajePhone = ''
        this.messageType = ''
      }
    },
    validLang () {
      if (!this.supportlang) {
        this.mensajeLang = 'In what language should we talk to you'
        this.messageTypeLang = 'is-primary'
        this.valid = false
      } else {
        this.mensajeLang = ''
        this.messageTypeLang = ''
      }
    },
    clearValidityEmail () {
      this.messageTypeEmail = ''
      this.mensajeEmail = ''
    },
    clearValidityPhone () {
      this.mensajePhone = ''
      document.getElementById('phone').classList.remove('invalid-input')
    },
    clearValidityLang () {
      this.messageTypeLang = ''
      this.mensajeLang = ''
    }
  },
  components: {
    VueTelInput
  }
}
</script>

<style>
@import "../../../node_modules/vue-tel-input/dist/vue-tel-input.css";
#contact_welcome input:-webkit-autofill,
#contact_welcome input:-webkit-autofill:hover,
#contact_welcome input:-webkit-autofill:active,
#contact_welcome input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
    box-shadow: 0 0 0 1000px white inset;
}

#contact_welcome .container-video-desktop {
  display: none;
}

#contact_welcome  .vue-tel-input {
  border: 1px solid #ECECEC !important;
}

.select:not(.is-multiple):not(.is-loading)::after, .navbar-link:not(.is-arrowless)::after {
  margin-top: 0px!important;
  top: 38% !important;
}

.container-welcome {
  margin-top: 50px;
}

@media (min-width: 959px) {

  .container-welcome {
    margin-top: 0px;
  }
  
  #contact_welcome .container-video-desktop {
    display: block;
  }

  .container-content-create-account {
    margin-left: 120px !important;
    margin-right: 0 !important;
  }

}

</style>
