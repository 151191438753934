import axios from 'axios'

const apiFirebase = axios.create({
  baseURL: `${process.env.VUE_APP_FIREBASE_FUNCTIONS}`,
  // baseURL: 'https://us-central1-emitus-5a6f2.cloudfunctions.net',
  // baseURL: 'http://localhost:4000/emitus-5a6f2/us-central1',

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const apiCloudinary = axios.create({
  baseURL: 'https://api.cloudinary.com/v1_1/dzneqacw6',

  headers: {
    'Content-Type': 'application/json'
  }
})

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_CONNECTION_URL}`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  // Send mesagges to Slack channel
  async testMessage (idToken, msg) {
    return await apiFirebase.post('/messaging/message',
      {
        message: msg
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      })
  },

  // Upload image to Cloudinary
  async uploadImageCloudinary (file) {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('timestamp', (Date.now() / 1000) | 0)
    formData.append('upload_preset', 'yetv2mty')

    return await apiCloudinary.post('/image/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },

  // Delete image to Cloudinary
  async deleteImageCloudinary (idToken, avatarCloudinaryId) {
    return await apiClient.delete(`/media/cloudinary/image/${avatarCloudinaryId}`,
      {
        headers: { idToken: idToken }
      })
  },

  // Upload image from Cloudinary to S3 emitusfull
  async uploadImageS3 (idToken, imgData) {
    return await apiFirebase.post('/upload/uploadPhotoFromCloudinaryToS3', imgData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      })
  },

  // Upload image from Cloudinary to S3 CDN (emituscdn.com)
  async uploadImageS3CDN (idToken, imgData) {
    const obj = {
      picture:
        {
          id: imgData.public_id,
          width: imgData.width,
          height: imgData.height
        },
      value: 15
    }
    return await apiFirebase.post('/upload/uploadPhotoFromCloudinaryToS3Gravity', obj,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`
        }
      })
  },

  // Delete image from S3
  async deleteImageS3 (idToken) {
    return await apiClient.delete('/media/s3/profilepic',
      {
        headers: { idToken: idToken }
      })
  }
}
