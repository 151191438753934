<template>
  <div>
    <router-link @click="this.notify.$emit('refreshNavBar')" to="/panel/me/edit-me">
      <div class="button-close has-text-right mr-5 mt-5">
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25 1L1 25"
            stroke="#9D9D9D"
            stroke-opacity="0.29"
            stroke-width="1.875"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M1 1L25 25"
            stroke="#9D9D9D"
            stroke-opacity="0.29"
            stroke-width="1.875"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </router-link>
    <div class="container-content-about is-flex is-justify-content-center is-align-content-center">
      <div class="container-video-desktop">
        <div class="video-hidde-mobile">
          <Video />
        </div>
      </div>
      <Contact
        @updateUserContact="updateUser"
        v-on:nextstep="nextstep"
        :user="user"
        :name="profile.name"
        :showEmail="showEmail"
        v-if="step === 1"/>

      <About
        @updateProfileGeneral="updateProfileGeneral"
        @updateUserAbout="updateUser"
        :user="user"
        :profile="profile"
        :dateofbirthp="dateofbirth"
        v-on:laststep="laststep"
        v-on:nextstep="nextstep"
        v-if="step === 2"/>

      <Picture
        @updateUserAvatar="updateUser"
        :avatarp="avatar"
        v-on:laststep="laststep"
        v-if="step === 3"/>
    </div>
  </div>
</template>

<script>
import firebaseConfig from '@/firebase/init.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import UserService from '@/services/UserService'
import ProfileService from '@/services/ProfileService'
import Contact from '@/components/welcome/contact.vue'
import About from '@/components/welcome/about.vue'
import Picture from '@/components/welcome/picture.vue'
import Video from '@/components/welcome/video.vue'

export default {
  name: 'Welcome',
  data () {
    return {
      step: 1,
      email: '',
      supportlang: 'English',
      phone: '',
      countryorigin: '',
      dateofbirth: new Date(),
      avatar: '',
      profile: {},
      user: {},
      authUser: null,
      showEmail: false
    }
  },
  components: {
    Contact,
    About,
    Picture,
    Video
  },
  metaInfo: {
    title: 'emitus | Welcome'
  },
  methods: {
    nextstep () {
      this.step++
    },
    laststep () {
      this.step--
    },

    // Update User
    updateUser (userData) {
      firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          await UserService.updateUser(idToken, userData)
          if (this.step === 3) {
            this.notify.$emit('updateData')
            this.$router.push('/panel/me/edit-me')
          }
          this.step++
        })
    },

    // Update Profile
    updateProfileGeneral (profile) {
      firebase.auth().currentUser.getIdToken(true)
        .then(async idToken => {
          await ProfileService.updateCurrentProfile(idToken, profile)
        })
    }

  },
  created () {
    // Get user from Firebase
    firebase.auth().onAuthStateChanged(user => {
      this.authUser = user
      // Show email or not
      if (this.authUser.email) {
        const emailAuth = this.authUser.email
        const domainEmail = emailAuth.substring(emailAuth.indexOf('@') + 1)
        if (this.authUser.providerData[0].providerId === 'password' ||
        domainEmail === 'privaterelay.appleid.com') {
          this.showEmail = true
        }
      } else {
        this.showEmail = true
      }
    })

    // Get Current User & Profile
    firebase.auth().currentUser.getIdToken(true)
      .then(idToken => {
        UserService.getCurrentUser(idToken)
          .then(user => {
            this.user = user.data
            this.email = this.user.email
          })
        ProfileService.getCurrentProfile(idToken)
          .then(resp => {
            this.profile = resp.data
            this.name = this.profile.name
          })
      })
  }
}

</script>

<style>
.container-video-desktop {
  display: none;
}

@media (min-width: 959px) {
  .container-video-desktop {
    display: block;
  }
}
</style>
