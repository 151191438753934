<template>
  <div class="container-content-create-account">
    <div>
      <div class="container-picture has-text-left pt-3">
        <!-- title -->
        <div class="title-header-section-create-account">
          <p
            class="
              title-section
              text-basier
              has-text-left
              title-regular
              dark-blue-emitus
              pt-2
            "
          >
            Foto de perfil
          </p>
        </div>
        <div class="mb-6">
          <p
            class="description-nunito dark-gray-emitus">No tienes que ponerte corbata, se tu mism@.</p>
        </div>

        <Upload />
      </div>
    </div>
  </div>
</template>

<script>
import Upload from '@/components/welcome/uploadpic.vue'

export default {
  name: 'Avatar',
  watch: {
    avatarp: function () {
      this.avatar = this.avatarp
    }
  },
  components: {
    Upload
  },
  props: {
    avatarp: {
      type: String
    }
  },
  data () {
    return {
      avatar: '',
      valid: true,
      messageAvatar: '',
      typeAvatar: ''
    }
  },

  methods: {
    laststep () {
      this.$emit('laststep')
    },
    updateUser () {
      this.validAvatar()
      if (this.avatar) {
        this.valid = true
      }
      if (this.valid) {
        this.$emit('updateUserAvatar',
          {
            avatar: this.avatar
          })
        // this.$root.$emit('isLogged')
      } else {
        document.getElementById('avatar_picture').classList.add('invalid-input')
      }
    },
    validAvatar () {
      if (!this.avatar) {
        this.messageAvatar = 'You don\'t need a tie ;)'
        this.typeAvatar = 'is-primary'
        this.valid = false
      } else {
        this.messageAvatar = ''
        this.typeAvatar = ''
      }
    },
    clearValidatyAvatar () {
      this.messageAvatar = ''
      this.typeAvatar = ''
    }
  }
}
</script>

<style scoped>

.container-video-desktop {
  display: none;
}

.container-picture {
  margin-top: 50px;
}

@media (min-width: 959px) {

  .container-picture {
    margin-top: 0px;
  }

  .container-video-desktop {
    display: block;
  }

  .container-content-create-account {
    margin-left: 120px;
    margin-right: 0;
  }
}

</style>

        // <form>
        //   <!-- url avatar user -->
        //   <b-field
        //     class="mt-6"
        //     label="Avatar Picture (url)"
        //     :message="messageAvatar"
        //     :type="typeAvatar"
        //     v-on:focusin.native="clearValidatyAvatar"
        //     >
        //     <b-input
        //       id="avatar_picture"
        //       v-model="avatar"
        //       autocomplete="off"
        //     >
        //     </b-input>
        //   </b-field>
        //   <!-- actualizar el user -->
        //   <button class="button-primary-mini mt-4" @click.prevent="updateUser">Ok</button>

        // </form>
