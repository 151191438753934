<template>
  <div id="welcome_about" class="container-content-create-account">
    <div>
      <!-- title -->
      <div class="has-text-left container-about-welcome">
        <div class="title-header-section-create-account">
          <p
            class="
              title-section
              text-basier
              has-text-left
              title-regular
              dark-blue-emitus
              pt-2
            "
          >
            Acerca de ti
          </p>
        </div>
        <p class="description-nunito dark-gray-emitus">Queremos conocerte mejor</p>

        <form>
          <!-- <b-field
            class="mt-5 pt-4"
            label="Dónde vives"
            :message="messageCountry"
            :type="typeCountry"
            v-on:focusin.native="clearValidityCountry"
            >

            <b-select
              v-on:focusout.native="validateCountry"
              v-model="countryorigin"
              placeholder="Elige el país"
              icon="earth"
              expanded
            >
              <option class="" v-if="countryorigin" disabled value="">{{countryorigin}}</option>
              <option class="" v-else disabled value="">Elige el país</option>
              <optgroup label="Most common">
                <option value="United States">Estados Unidos</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Germany">Germany</option>
                <option value="France">France</option>
                <option value="Italy">Italy</option>
                <option value="Spain">Spain</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Japan">Japan</option>
                <option value="India">India</option>
                <option value="Netherlands">Netherlands</option>
                <option value="Poland">Poland</option>
                <option value="Canada">Canada</option>
              </optgroup>
              <optgroup label="Other">
                <option
                  v-for="(country, idx) in countries"
                  :key="idx"
                  :value="country"
                >
                  {{ country }}
                </option>
              </optgroup>
            </b-select>
          </b-field>
         <p id="country_about" style="color:red; display:none;" class="text-nunito-light is-size-7">Campo requerido</p> -->

          <!-- profession -->
          <b-field
            class="mt-5"
            label="Ocupación"
            :message="messageOcupation"
            :type="typeOcupation"
            v-on:focusin.native="clearValidityOcupation"
            >
            <b-input
              v-on:focusout.native="validateTitle"
              id="title_about"
              v-model="title"
              autocomplete="off"
             placeholder="Abogado civil...">
            </b-input>
          </b-field>

          <!-- date of birth -->
          <b-field class="date-of-birth mt-5" label="Fecha de nacimiento">
            <b-datepicker
              v-model="dateofbirth"
              placeholder="Click para seleccionar..."

              :locale="locale"
              trap-focus
              editable
              :max-date="maxDate"
            >
            </b-datepicker>
          </b-field>
          <!-- update user -->
          <button class="button-primary-mini mt-6" @click.prevent="updateUser">Ok</button>
          <!--
          <div v-if="loader" class="lds-ring">
            <div></div>
            <span class="text-basier purple-dark-emitus loader-text">Loading...</span>
          </div>-->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'

var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export default {
  name: 'About',
  props: {
    user: {
      type: Object,
      required: true
    },
    profile: {
      type: Object,
      required: true
    },
    dateofbirthp: {
      type: Date
    }
  },
  watch: {
    // user: function () {
    //   this.countryorigin = this.user.countryorigin
    // },
    // profile: function () {
    //   this.title = this.profile.title
    // },
    // countryoriginp: function () {
    //   this.dateofbirth = this.dateofbirthp
    // }
  },
  data () {
    const today = new Date()
    return {
      valid: true,
      messageCountry: '',
      messageOcupation: '',
      typeCountry: '',
      typeOcupation: '',
      date: new Date(),
      maxDate: new Date(today.getFullYear() - 18, today.getMonth(), today.getDate()),
      countryorigin: 'Spain',
      dateofbirth: null,
      title: '',
      locale: undefined, // Browser locale,
      countries: [
        'Andorra',
        'Argentina',
        'Armenia',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahrein',
        'Belgium',
        'Benin',
        'Bhutan',
        'Brazil',
        'British Indian Ocean Territory',
        'Bulgaria',
        'Chile',
        'China',
        'Canada',
        'Costa Rica',
        'Cote Ivoire',
        'Comoros',
        'Croatia',
        'Curacao',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Estonia',
        'Falkland Islands (Malvinas)',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Polynesia',
        'French Guiana',
        'Georgia',
        'Germany',
        'Gibraltar',
        'Greece',
        'Guadeloupe',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'Ireland',
        'Israel',
        'Italy',
        'Japan',
        'Jordan',
        'Kenya',
        'Korea',
        'Kiribati',
        'Latvia',
        'Lesotho',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Mayotte',
        'Martinique',
        'Malta',
        'Mauritius',
        'Mexico',
        'Moldova',
        'Monaco',
        'Morocco',
        'Netherlands',
        'New Caledonia',
        'New Zealand',
        'Norway',
        'Oman',
        'Paraguay',
        'Peru',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Rwanda',
        'Saint Pierre And Miquelon',
        'San Marino',
        'Saudi Arabia',
        'Saint Barthelemy',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'Solomon Islands',
        'Spain',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Taiwan',
        'Tanzania',
        'Thailand',
        'Togo',
        'Tonga',
        'Turkey',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Vietnam',
        'Wallis And Futuna'
      ],
      loader: false
    }
  },

  methods: {
    nextstep () {
      this.$emit('nextstep')
    },
    laststep () {
      this.$emit('laststep')
    },
    updateUser () {
      this.validateTitle()
      this.validateCountry()
      // console.log(this.valid)
      if (this.title && this.countryorigin) {
        this.valid = true
      }
      // console.log(this.valid)
      if (this.valid) {
        this.loader = true
        const dateofbirthOk = dayjs(this.dateofbirth).utc(true).toDate()
        this.$emit('updateUserAbout', {
          countryorigin: this.countryorigin,
          dateofbirth: dateofbirthOk
        })
        this.$emit('updateProfileGeneral', {
          title: this.title
        })
        // this.loader = false
      }
    },
    validateTitle () {
      if (!this.title) {
        this.messageOcupation = 'Te falta este campo (Ejemplo: "Abogado Cívil"). '
        this.typeOcupation = 'is-primary'
        this.valid = false
      } else {
        this.messageOcupation = ''
        this.typeOcupation = ''
      }
    },
    validateCountry () {
      if (!this.countryorigin) {
        this.messageCountry = 'Don\'t forget this. Santa wouldn\'t bring you anything.'
        this.typeCountry = 'is-primary'
        this.valid = false
      } else {
        this.messageCountry = ''
        this.typeCountry = ''
      }
    },

    clearValidityOcupation () {
      this.messageOcupation = ''
      this.typeOcupation = ''
    },
    clearValidityCountry () {
      this.messageCountry = ''
      this.typeCountry = ''
    }
  },
  created () {
    this.dateofbirth = new Date(this.dateofbirthp.getFullYear() - 18, this.dateofbirthp.getMonth(), this.dateofbirthp.getDate())
    // this.countryorigin = this.countryoriginp
    this.title = this.titlep
    // console.log(this.dateofbirth)
  }
}
</script>

<style scoped>
.container-video-desktop {
  display: none;
}

.container-about-welcome {
  margin-top: 50px;
}

@media (min-width: 959px) {

  .container-about-welcome {
    margin-top: 0px;
  }

  .container-video-desktop {
    display: block;
  }

  .container-content-create-account {
    margin-left: 120px;
    margin-right: 0;
  }
}
</style>
