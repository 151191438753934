<template>
  <div class="video-h-desktop emitus-video-join video-mask-join" style="position: relative;">
    <!-- progress bar -->
    <b-progress
      id="progressbar-d"
      class="front kkiki"
      is-small
      style="color:#6E3DEA !important; display: none; margin-bottom: -3px; height: 5px; background-color: rgba(255, 255, 255, 0.5); width: 282px; left: 11px; border-radius: 5px;"
      :type="type"
      :max="100"
      :value="valueVideo"
      @change="displayProgressBar"
    ></b-progress>
    <!-- video -->
    <video
        class="video-w-mobile video-height-mobile"
        style ="height: 100%; position: relative;"
        :src="'https://player.vimeo.com/progressive_redirect/playback/686771701/rendition/720p?loc=external&signature=02120ed0e6f58de25537d0e9b1f3623cac62a67ac10e92fb3b2584c0d76e5ab8'"
        ref="bgvid-d"
        id="bgvid-d"
        playsinline
        muted
        autoplay
        loop
    ></video>

    <div class="hero-body filter front" style="position: absolute;">
      <div
        class="container is-flex "
      >
        <!-- what the video does just load the page -->
        <div
          @click="displayProgressBar"
          id="load-d"
          class="invisible"
          onclick="document.getElementById('bgvid-d').currentTime = 0;
                    document.getElementById('load-d').style.display = 'none';
                    document.getElementById('pause-d').style.display = 'block';
                    document.getElementById('progressbar-d').style.display = 'block';
                    document.getElementById('bgvid-d').muted = !document.getElementById('bgvid-d').muted"
        >
          <div class="center is-horizontal-center">
            <figure class="image is-64x64">
              <img class="m-center-icon-play-mobile" src="@/assets/images/landing/icon-play-desktop.svg" />
            </figure>
          </div>
        </div>

        <!-- pause video -->
        <div
          @click="stopVideo"
          id="pause-d"
          style="display: none"
          class="invisible"
          onclick="document.getElementById('bgvid-d').pause();
                    document.getElementById('pause-d').style.display = 'none';
                    document.getElementById('play-d').style.display = 'block';"
        >
          <img src="@/assets/images/landing/invisible.png" />
        </div>

        <!-- play video -->
        <div
          @click="displayProgressBar"
          id="play-d"
          style="display: none"
          class="invisible"
          onclick="document.getElementById('bgvid-d').play();
                    document.getElementById('play-d').style.display = 'none';
                    document.getElementById('pause-d').style.display = 'block';
                    "
        >
          <div class="center is-horizontal-center">
            <figure class="image is-64x64">
              <img class="m-center-icon-play-mobile"  src="@/assets/images/landing/icon-play-desktop.svg" />
            </figure>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  layout: 'profile',
  // OR
  layout (context) {
    return 'profile'
  },
  data () {
    return {
      type: 'is-primary',

      valueVideo: null,
      nIntervId: 0,
      isDisabled: true
    }
  },
  created () {},
  methods: {
    displayProgressBar () {
      const v = this
      this.nIntervId = setInterval(function () {
        var duration = document.getElementById('bgvid-d').duration
        var currentTime = document.getElementById('bgvid-d').currentTime
        v.valueVideo = Math.round((currentTime / duration) * 100)
      }, 500)
    },

    stopVideo () {
      // const v = this;
      // console.log('Stop!')
      clearInterval(this.nIntervId)
    }
  },

  mounted () {
    this.displayProgressBar()
  },
  destroyed () {
    clearInterval(this.nIntervId)
  }
}
</script>

 <style scoped>

@media (max-width: 768px) {

  .video-w-mobile {
    width: 100% !important;
  }
  .video-height-mobile {
    height: 100vh !important;
  }
  .video-min-mobile {
    min-width: 360px;
    min-height: 600px;
  }
  video source {
    height: 100%;
    width: 100%;
  }
  .m-center-icon-play-mobile {
    margin-top: -100px;
  }
}
@media (min-width: 768px) {
  .video-height-desktop {
    height: 100%;
  }
}

.filter {
  width: 100%;
  height: 100vh;
  z-index: 1;
  position: absolute;
  top: 0px;
  opacity: 1;
  background-color: #0d123933;
}

.invisible {
  width: 100%;
  height: 100vh;
  z-index: 1;
  top: 0px;
  opacity: 1;
  background-color: #0d123900;
}

.center {
  display: flex;
  flex-direction: column;
  align-items: center;

  /* margin-top: -75px; */
}

.is-horizontal-center {
  justify-content: center;

}

@media only screen and (min-width: 959px) {
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .is-horizontal-center {
    justify-content: center;
    height: 100%;
  }

  .hero-video {
    display: block;
    left: -1px;
  }

  .emitus-video-join video {
    object-fit: cover;
  }

  .hero-body {
    padding: 0;
  }

  .hero.is-fullheight {
    background-color: #0d1239;
  }

  /* .control {
    max-width: 64px;
    margin-top: -60px;
  } */

  .filter {
    width: 297px;
    height: 540px;
    z-index: 1;
    position: absolute;
    opacity: 1;
    background-color: rgba(13, 18, 57, 0.2);
    border-radius: 20px;
    overflow: hidden;
  }

  .invisible {
    width: 297px;
    height: 540px;
    z-index: 1;
    top: 0px;
    opacity: 1;
    background-color: #0d123900;
  }

  .video-mask-join {
    border-radius: 20px;
    overflow: hidden;
    height: 540px;
    width: 297px;
  }
}
</style>
