<template>
  <div>
    <!-- Add files -->
    <section v-if="files.length < 2">
    <!-- <section v-if="files.length < 2 && status !== 'Verified'"> -->
        <div
         class="container mt-10 container-picture">
          <div>

            <!-- Preview Image -->
            <img
              class="img-avatar-preview"
              v-if="image"
              :src="image"
              alt="card_image">

            <!-- Drop image -->
            <b-field v-if="showFileDrop">
                <b-upload
                    v-model="dropFiles"
                    multiple
                    @change.native="handleImage"
                    drag-drop>
                    <section class="section">
                        <div class="content has-text-centered">
                            <img
                              class="mb-5"
                              src="https://emituscdn.com/Upload-icon.svg">
                            <p class="text-hover-description description-nunito gray-emitus">
                              Arrastra tu foto aquí o haz click para subirla</p>
                        </div>
                    </section>
                </b-upload>
            </b-field>

            <div class="is-flex mt-4">
              <!-- C2A Cancell-->
              <b-button
                v-if="image"
                @click="fileCancel"
                class="
                  button-secundary-small
                  text-basier-medium
                ">
                cancelar
              </b-button>

              <!-- C2A OK-->
              <b-button
                v-if="image"
                @click="uploadImage"
                class="
                  button-primary-medium
                  ml-4
                "
                >
                Guardar
              </b-button>
              <div v-if="loader">
                <Loading/>
              </div>
            </div>

          </div>
        </div>
    </section>

  </div>
</template>

<script>
import Loading from '@/components/modals/loader.vue'
import firebase from 'firebase/app'
import 'firebase/auth'
import Media from '@/services/MediaService'
import UserService from '@/services/UserService'

export default {
  components: {
    Loading
  },
  name: 'UploadPic',

  data () {
    return {
      user: null,
      files: [],
      dropFiles: [],
      image: '',
      status: null,
      refusedReason: null,
      loader: false
    }
  },

  // CREATED

  async created () {
    // Listen to data
    this.notify.$on('updateUser', (user) => {
      this.user = user
      this.status = user.verified
      this.refusedReason = user.refusedReason
    })

    await this.notify.$emit('updateData')
  },

  // COMPUTED

  computed: {
    showFileDrop: function () {
      if (this.dropFiles.length > 0) {
        return false
      } else {
        return true
      }
    }

  },

  // WATCH

  watch: {
    dropFiles: function () {
      if (this.dropFiles.length > 0) {
        this.handleImage()
      }
    }
  },

  // METHODS
  methods: {
    // Handle Image
    async handleImage (e) {
      const selectedImage = this.dropFiles[0]
      // const selectedImage = e.target.files[0] // get first file
      this.createBase64Image(selectedImage)
    },
    async createBase64Image (fileObject) {
      const reader = new FileReader()

      reader.onload = (e) => {
        this.image = e.target.result
      }
      reader.readAsDataURL(fileObject)
    },

    // file cancell
    async fileCancel () {
      this.dropFiles = []
      this.image = ''
    },

    // Upload image
    async uploadImage () {
      this.loader = true
      const idToken = await firebase.auth().currentUser.getIdToken(true)

      // Upload image to Cloudinary
      const response = await Media.uploadImageCloudinary(this.image)
        .catch(err => {
          console.log('----> Error uploadImageCloudinary', err)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> Uploaded image', response)

      const imgData = {
        public_id: response.data.public_id,
        width: response.data.width,
        height: response.data.height,
        version: response.data.version
      }

      // Upload image to S3 Full
      const pictureS3Full = await Media.uploadImageS3(idToken, imgData)
        .catch(err => {
          console.log('----> Error uploadImageS3', err)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> pictureS3Full', pictureS3Full)

      // Upload image to S3 CDN
      const pictureS3CDN = await Media.uploadImageS3CDN(idToken, imgData)
        .catch(err => {
          console.log('----> Error uploadImageS3CDN', err)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> uploadImageS3CDN', pictureS3CDN)

      // Save url to DB: user.avatar
      const url = 'https://' + pictureS3CDN.data.rta.Location.slice(25)
      const user = await UserService.updateUser(idToken,
        {
          avatar: url,
          avatarCloudinaryId: imgData.public_id,
          avatarS3Key: pictureS3CDN.data.rta.Key
        })
        .then(() => {
          this.notification('Foto de perfil guardada')
          this.loader = true
        })
        .catch(err => {
          console.log('----> Error updateUser', err)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> avatar', user)

      // Delete image from Cloudinary
      const responseDelete = await Media.deleteImageCloudinary(idToken, imgData.public_id)
        .catch(err => {
          console.log('----> Error deleteImageCloudinary', err.response.data.msg)
          this.danger('Algo salió mal')
          this.loader = false
        })
      // console.log('----> Delete image from Cloudinary', responseDelete)

      if (responseDelete.data.msg !== 'Image successfully deleted') {
        this.danger(responseDelete.data.msg)
        this.loader = false
      }

      // Update avatar in App
      this.notify.$emit('updateData')
      this.$router.push('/panel/me/edit-me')
    },

    // Notifications
    danger (msg) {
      this.$buefy.toast.open({
        duration: 3000,
        message: msg,
        position: 'is-bottom',
        type: 'is-danger'
      })
    },
    notification (msg) {
      this.$buefy.toast.open({
        duration: 1000,
        message: msg,
        position: 'is-top-right',
        type: 'is-success'
      })
    }

  }

}
</script>

<style scoped>
.title {
  font-size: 24px;
}
.container-picture {
  margin: 0 !important;
  width: 263px;
}

.text-hover-description:hover {
  color: #6E3DEA;
}

.mdi-upload::before {
  background-image: url(https://dev.emitus.com/Upload-icon.svg) !important;
}
.img-avatar-preview {
  object-fit: cover;
  width: 100%;
  height: 280px;
}
@media (min-width: 768px) {
  .img-avatar-preview {
    object-fit: cover;
    width: 100%;
    height: 280px;
  }
}
</style>
